<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="0"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :style="styles"
        :value="formattedDate"
        :label="label"
        :placeholder="placeholder"
        :loading="loading"
        :disabled="disabled"
        readonly
        :color="color"
        :clearable="clearable && !readonly"
        :outlined="outlined"
        :dense="dense"
        :error-messages="errorMessages"
        v-on="!readonly ? on : null"
        @click:clear="handleClearClick"
      />
    </template>
    <v-date-picker
      :first-day-of-week="firstDayOfWeek"
      :max="maxDate"
      :min="minDate"
      :locale="$store.state.language"
      v-model="date"
      @input="handleInput"
      color="primary"
      :no-title="noTitle"
      :allowed-dates="allowedDates"
      scrollable
    >
      <v-spacer />
      <v-btn v-if="showCancelButton" flat color="primary" @click="menu = false">{{ cancelButtonLabel }}</v-btn>
      <v-btn v-if="showOkButton" flat color="primary" @click="handleOkClick">{{ okButtonLabel }}</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2021-01-06 14:34:47
   * @modify date 2021-01-06 14:34:47
   * @desc DatePicker
   */

  export default {
    name: "DatePicker",
    props: {
      value: {
        required: true,
        validator: prop => typeof prop === "string" || !prop
      },
      label: {
        type: String,
        required: false
      },
      placeholder: {
        type: String,
        required: false
      },
      icon: {
        type: String,
        required: false,
        default: "mdi-calendar"
      },
      color: {
        type: String,
        required: false,
        default: "tertiary"
      },
      errorMessages: {
        type: Array,
        default: () => []
      },
      cancelButtonLabel: {
        type: [String, Object],
        required: false,
        default() {
          return this.$t("buttons.cancel");
        }
      },
      okButtonLabel: {
        type: [String, Object],
        required: false,
        default() {
          return this.$t("buttons.ok");
        }
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      clearable: {
        type: Boolean,
        required: false,
        default: true
      },
      readonly: {
        type: Boolean,
        required: false,
        default: false
      },
      noTitle: {
        type: Boolean,
        required: false,
        default: false
      },
      showCancelButton: {
        type: Boolean,
        required: false,
        default: false
      },
      showOkButton: {
        type: Boolean,
        required: false,
        default: false
      },
      allowedDates: {
        type: Function,
        required: false,
        default: null
      },
      styles: {
        type: [String, Object],
        required: false,
        default: null
      },
      max: {
        type: String,
        required: false
      },
      min: {
        type: String,
        required: false
      },
      firstDayOfWeek: {
        type: [String, Number],
        required: false,
        default: 1
      },
      outlined: {
        type: Boolean,
        required: false,
        default: false
      },
      dense: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data: () => ({
      menu: false,
      date: null
    }),
    watch: {
      date(val) {
        if (!this.showOkButton) {
          this.$emit("input", val);
        }
      },
      value(val) {
        this.date = val;
      }
    },
    computed: {
      formattedDate() {
        return this.$helpers.formatTimeZoneDate(this.date);
      },
      maxDate() {
        return this.max ? this.$moment.utc(this.max).format("YYYY-MM-DD") : null;
      },
      minDate() {
        return this.min ? this.$moment.utc(this.min).format("YYYY-MM-DD") : null;
      }
    },
    methods: {
      handleInput() {
        if (!this.showOkButton) {
          this.$refs.menu.save(this.date);
        }
      },
      handleOkClick() {
        this.$refs.menu.save(this.date);
        this.$emit("input", this.date);
      },
      handleClearClick() {
        this.date = null;
        this.$emit("input", null);
      }
    },
    created() {
      this.date = this.value;
    }
  };
</script>
